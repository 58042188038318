<template>
  <wrinkles-template
    :wrinkles="wrinkles"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import WrinklesTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/wrinkles/WrinklesTemplate';

import { no } from '@/modules/questionnaire/api/helpers';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { FACE_WRINKLES } from '@/modules/questionnaire/api/constants';

const { field, requiredField } = fieldBuilder;

const FIELDS_TO_RESET = [
  'foreheadLinesDepth',
  'frownLinesDepth',
  'crowsFeetDepth',
  'bunnyLinesDepth',
  'tearThroughDepth',
  'nasolabialFoldsDepth',
  'verticalLipLinesDepth',
  'mouthFrownDepth',
  'marionetteLinesDepth',
  'chinCreaseDepth',
  'neckWrinkleDepth'
];

const FIELDS_TO_RESET_NO = ['skinAppearanceDiscomfort'];

const FIELDS = [
  requiredField('wrinkles'),
  ...FIELDS_TO_RESET.map(field),
  ...FIELDS_TO_RESET_NO.map(field)
];

export default {
  name: 'Wrinkles',
  components: { WrinklesTemplate },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    wrinkles(newValue) {
      if (newValue === FACE_WRINKLES.FEW_FACE_LINES || newValue === FACE_WRINKLES.NO) {
        this.resetStoreFieldsByName(FIELDS_TO_RESET);
      }

      if (no(newValue)) {
        this.resetStoreFieldsByName(FIELDS_TO_RESET_NO);
      }

      this.showNextStep();
    }
  }
};
</script>
